import React, { useCallback, useEffect } from 'react'

import Layout from 'components/layout'
import HeroComponent from 'components/hero'
import CallToAction from 'components/callToAction'
import Blog from 'components/blog'
import Seo from 'components/seo'

import { graphql } from 'gatsby'

const BlogPage = ({ data, location }) => {
  const {
    sanityBlogPage: {
      tabsBlogPage: { seo, Hero, Call_to_Action },
    },
    sanityAppereance: {
      tabsAppereance: { header, footer },
    },
    allSanityBlogPost,
  } = data

  useEffect(() => {
    console.log('allSanityBlogPost')
    console.log(allSanityBlogPost)
  }, [])

  const setCallToActionBtn = useCallback(() => {
    console.log('click btn contáctanos')
  }, [])

  const formatText = (str, max, suffix) => {
    return str.length < max
      ? str
      : `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(' ')
        )}${suffix}`
  }

  return (
    <Layout header={header} footer={footer}>
      {seo?.seo?.focus_keyword && seo?.author && seo?.seo?.focus_synonyms && (
        <Seo data={seo} />
      )}
      {Hero?.enable && (
        <HeroComponent
          navbar={Hero?.logoEmpresaHeader?.image?.asset?.url}
          navbarAlt={Hero?.logoEmpresaHeader?.alt}
          imageBackground={Hero?.image.image.asset.url}
          title={Hero?.title}
          subTitle={Hero?.description}
          btnText={Hero?.cta?.text}
          btnCallToAction={setCallToActionBtn}
          btnEnable={Hero?.cta?.enable}
          btnLink={Hero?.cta?.link}
          location={location.pathname}
        />
      )}
      {/* <EncabezadoMain
        title={blogEncabezado?.text}
        paragraph={blogEncabezado?.description}
      /> */}
      {allSanityBlogPost.nodes.map(post => post?.text?.length > 0 && (
        <Blog
          imgPrincipal={post?.image?.image?.asset?.url}
          titleBlog={post?.title}
          subTitleBlog={post?.subtitle}
          contentBlog={formatText(post?.text[0]?.children[0]?.text, 500, '.')}
          linkBtn={`/blog/${post?.slug?.current}`}
        />
      ))}
      {Call_to_Action?.enable && (
        <CallToAction
          title={Call_to_Action?.title}
          paragraph={Call_to_Action?.description}
          btnText={Call_to_Action?.cta.text}
          btnEnable={Call_to_Action?.cta.enable}
          btnCallToAction={setCallToActionBtn}
        />
      )}
    </Layout>
  )
}

export const BlogQuery = graphql`
  query BlogQuery {
    sanityBlogPage(slug: { current: { eq: "blog-page" } }) {
      tabsBlogPage {
        seo {
          author
          _key
          seo {
            seo_title
            meta_description
            focus_synonyms
            focus_keyword
            _type
            _key
          }
          ogImage {
            asset {
              url
            }
          }
        }
        Hero {
          title
          enable
          description
          cta {
            enable
            link
            text
          }
          image {
            alt
            image {
              asset {
                url
              }
            }
          }
          logoEmpresaHeader {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        Call_to_Action {
          description
          enable
          title
          cta {
            enable
            link
            text
          }
        }
        BlogPage {
          enable
        }
      }
    }
    sanityAppereance(slug: { current: { eq: "apariencia" } }) {
      tabsAppereance {
        footer {
          correo1
          correo2
          correo3
          departamento
          direccion
          enableFooter
          footerParagrah
          footerTitle
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkInstagram
          linkLinkedin
          linkTwitter
          messenger
          telefono
        }
      }
    }
    allSanityBlogPost(limit: 4, sort: { fields: _id, order: DESC }) {
      nodes {
        title
        subtitle
        image {
          image {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        text {
          children {
            text
          }
        }
      }
    }
  }
`

export default BlogPage
